<template>
    <NcButton type="primary" :wide="true" @click="handleClick">
        {{ !loading ? value : valueLoading }}
        <template #icon>
            <div v-if="loading" class="submit-wrapper__icon icon-loading-small-dark" />
            <ArrowRight v-else class="submit-wrapper__icon" />
        </template>
    </NcButton>
</template>

<script>
import NcButton from '@nextcloud/vue/dist/Components/NcButton.js'
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue'

export default {
    name: 'ResendCodeButton',
    components: {
        ArrowRight,
        NcButton,
    },
    props: {
        value: {
            type: String,
            default: "Изпрати нов код"
        },
        valueLoading: {
            type: String,
            default: "Изпращане ..."
        },
        loading: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        handleClick() {
            console.log('resendButton clicked'); // <-- Check if this appears in console
            this.$emit('resend-code'); // <-- Event emitted
        }
    }
}
</script>

<style lang="scss" scoped>
.button-vue {
    margin-top: .5rem;
}
</style>
