<template>
<div class="why">
	<h1 class="why__header">Защо Disk.bg?</h1>
	<div class="why__slider-container">
		<div class="slick-initialized slick-slider">
			<button type="button" data-role="none" class="slick-arrow slick-prev" style="display: block;"> Previous</button>
			<div class="slick-list">
				<div class="slick-track" style="opacity: 1;">
					<div data-index="-1" class="slick-slide slick-active" :style="{width: trackWidth}">
						<div class="why__slider-image-content">
							<img class="why__slider-image" v-bind:src="require('/themes/diskbg/core/img/slider-img.jpg')" alt="Slide 1">
							</div>
							<div class="why__slider-text-content">
								<h1 class="why__slider-title">Лесен за използване</h1>
								<div class="why__slider-text">
									<p>Disk.bg предоставя сигурно и надеждно пространство за съхранение на файлове, както и бърз достъп до тях от различни крайни устройства, така и дългосрочен архив на файлове и документи без ограничение в размера или типа на данните. Регистрацията е безплатна, заедно с 10GB свободно място за съхранение. Друго удобство е плъгинът за продуктите на Microsoft Office (2010, 2013 и 2016) , чрез който можете директно да запазвате файловете, които създавате в своето облачно пространство.</p>
									<br>
										<p>Можете да получите достъп до файловете си в Disk.bg от всеки смартфон, таблет или компютър. Така че, където и да отидете, вашите файлове са с вас.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
		</div>
	</div>
	<button type="button" data-role="none" class="slick-arrow slick-next" style="display: block;"> Next</button>
	<ul class="slick-dots" style="display: block;">
		<li class="slick-active">
			<button>1</button>
		</li>
	</ul>
	</div>
</template>
<script>
export default {
    name: 'DiskbgWhy',
    data() {
        return {
            loading: false,
			trackWidth: '1200px',
        }
    },
    computed: {

    },

	mounted() {
        // Call the method to set initial width and add resize event listener
        this.setTrackWidth();
        window.addEventListener('resize', this.setTrackWidth);
    },

	beforeDestroy() {
        // Remove the resize event listener to prevent memory leaks
        window.removeEventListener('resize', this.setTrackWidth);
    },
    methods: {
		setTrackWidth() {
            // Get the window width and set the track width accordingly
			if(window.innerWidth>1290){
				this.trackWidth = '1200px';
			}
			else {
				this.trackWidth = `${window.innerWidth * 0.721373}px`;
			}

        },
    },
}
</script>
