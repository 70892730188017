<template>
	<div
		class="plan"
		:class="{ 'plan--selected': selectedPrice === selectedPlan.id }"
	>
		<div
			class="plan__best-selling"
			v-if="recommendedPriceId === selectedPlan.id"
		>
			<span class="plan__best-selling-text">най-продаван</span>
		</div>
		<h1 class="plan__size">{{ this.plan.name }}</h1>
		<div class="plan__slider">
			<span class="plan__slider-circle plan__slider-circle--min"></span>
		</div>
		<div class="select plan__select">
			<select @change="onChangePlan($event)">
				<option v-for="period in plan.periods" :value="period.id">
					{{ translatePeriods(period.period) }}
				</option>
			</select>
			<div class="select__arrow"></div>
		</div>
		<div class="plan__price">
			<span class="plan__price--huge">{{ selectedPlan.price }}</span
			>лв.<br />
			с ДДС
		</div>
		<!-- :class="
				selectedPrice === selectedPlan.id
					? ' btn--gradient-darkblue'
					: selectedPrice == !selectedPlan.id
					? ' btn--gradient-lightblue'
					: ' btn--gradient-darkblue'
			" -->
		<p class="plan__description">{{ selectedPlan.description }}</p>
		<button @click="onButtonClick"
			v-if="selectedPrice === selectedPlan.id"
			class="btn btn--gradient-darkblue"
			style="width: 200px"
		>
			<span class="text-bold">Избран</span>
		</button>
		<button @click="onButtonClick" v-else class="btn btn--gradient-lightblue" style="width: 200px">
			<span class="text-bold">Купи</span>
		</button>
	</div>
</template>
<script>

export default {
	name: "DiskbgSubscriptionPlan",
	props: ["plan", "recommendedPriceId", "selectedPrice", "onChangePrice"],
	setup(props) {},
	data() {
		return {
			loading: false,
			selectedPlan: "",
			recommended: false,
		};
	},
	computed: {},
	methods: {
		onButtonClick(){
			window.location.href = '/login?=true';
		},
		onChangePlan(event) {
			const id = parseInt(event.target.value, 10);
			this.selectedPlan = this.plan.periods.find((el) => el.id === id);
			this.onChangePrice(this.selectedPlan.id);
		},
		translatePeriods(periodInterval) {
			if (periodInterval === 1) {
				return "1 месец";
			} else if (periodInterval === 6) {
				return "6 месеца";
			} else {
				return "1 година";
			}
		},
	},
	mounted() {
		this.selectedPlan = this.plan.periods[0];
	},
};
</script>
