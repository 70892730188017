import Vue from 'vue';

// eslint-disable-next-line no-unused-vars
import OC from './OC/index.js' // TODO: Not needed but L10n breaks if removed
import DiskbgLandingPage from './views/DiskbgLandingPage.vue'
import Nextcloud from './mixins/Nextcloud.js'

Vue.mixin(Nextcloud)

const View = Vue.extend(DiskbgLandingPage)
new View().$mount('#diskbg-landingpage')
