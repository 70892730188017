<template>
	<div class="subscription">
		<h1 class="subscription__header">Абонаментни планове</h1>
		<div class="subscription__plans-wrapper">
			<div class="subscription__plans">
				<DiskbgSubscriptionPlan v-for="plan in this.plans" :key="plan.name" :plan="{ ...plan }"
					:recommendedPriceId="recommendedPriceId" :selectedPrice="selectedPriceId"
					:onChangePrice="onChangePrice" />
			</div>
			<span class="subscription__text">или</span>
			<div class="subscription__button-container">
				<a href="/apps/diskbg/register">
					<button class="btn btn--rounded btn--gradient-darkblue">
						<span>
							<i class="subscription__icon-add-user icon-add-user"></i>
							<span class="subscription__button-text">Регистрирай се сега</span>
						</span>
					</button>
				</a>
			</div>
			<span class="subscription__additional__text">и вземи 10 GB безплатно пространство</span>
		</div>
	</div>
</template>
<script>
import DiskbgSubscriptionPlan from "./DiskbgSubscriptionPlan.vue";
import Axios from "@nextcloud/axios";

export default {
	name: "DiskbgSubscriptions",
	components: {
		DiskbgSubscriptionPlan,
	},
	data() {
		return {
			loading: false,
			selectedPriceId: 4,
			recommendedPriceId: 4,
			plan2: "",
			plans: [
				{
					name: "100 GB",
					space: 100,
					periods: [
						{
							id: 1,
							period: 1,
							price: "3.99",
							description: null,
							pricePerGB: "0.0399000",
						},
						{
							id: 2,
							period: 6,
							price: "19.95",
							description:
								"5\u043c. + 1 \u0431\u0435\u0437\u043f\u043b\u0430\u0442\u0435\u043d",
							pricePerGB: "0.0399000",
						},
						{
							id: 3,
							period: 12,
							price: "35.91",
							description:
								"9\u043c. + 3 \u0431\u0435\u0437\u043f\u043b\u0430\u0442\u043d\u0438",
							pricePerGB: "0.0399000",
						},
					],
				},
				{
					name: "500 GB",
					space: 500,
					periods: [
						{
							id: 4,
							period: 1,
							price: "14.99",
							description: null,
							pricePerGB: "0.0299000",
						},
						{
							id: 5,
							period: 6,
							price: "74.95",
							description:
								"5\u043c. + 1 \u0431\u0435\u0437\u043f\u043b\u0430\u0442\u0435\u043d",
							pricePerGB: "0.0299000",
						},
						{
							id: 6,
							period: 12,
							price: "134.91",
							description:
								"9\u043c. + 3 \u0431\u0435\u0437\u043f\u043b\u0430\u0442\u043d\u0438",
							pricePerGB: "0.0299000",
						},
					],
				},
				{
					name: "1 TB",
					space: 1024,
					periods: [
						{
							id: 7,
							period: 1,
							price: "19.99",
							description: null,
							pricePerGB: "0.0195000",
						},
						{
							id: 8,
							period: 6,
							price: "99.95",
							description:
								"5\u043c. + 1 \u0431\u0435\u0437\u043f\u043b\u0430\u0442\u0435\u043d",
							pricePerGB: "0.0195000",
						},
						{
							id: 9,
							period: 12,
							price: "179.91",
							description:
								"9\u043c. + 3 \u0431\u0435\u0437\u043f\u043b\u0430\u0442\u043d\u0438",
							pricePerGB: "0.0195000",
						},
					],
				},
			],
		};
	},
	computed: {},
	methods: {
		getStoragePlans() {
			// return axios.get(url, {
			// 	headers,
			// 	params: {
			// shared_with_me,
			// include_tags: true,
			// },
			// })
			const headers = {
				"Content-Type": "application/json",
				Accept: "application/json",
			};
			Axios.get("/apps/diskbg/s/getStoragePlans", {
				headers,
				mode: "no-cors",
			})
				.then(async (resp) => {
					this.plan2 = await resp.json();
					console.log(this.plan2);
				})
				.catch((res) => {
					console.log(res);
				});
		},
		onChangePrice(priceId) {
			this.selectedPriceId = priceId;
		},
	},
	mounted() {
		this.getStoragePlans();
	},
};
</script>
