<template>
	<div>
		<DiskbgHeader
			:showLogin="showLoginForm"
			:showDefaultPage="showLandingPage"
		/>
		<div id="app-content-diskbg">
			<template v-if="showLogin">
				<div class="page">
					<div class="form-page">
						<h1 class="form-page__title">Влезте в Disk.bg</h1>
						<form class="form form-base">
							<Login />
						</form>
						<div class="form-page__bottom-link">
							<a
								class="anim-underline"
								href="/apps/diskbg/register"
								>Регистрирай се в Disk.bg</a
							>
						</div>
					</div>
				</div>
			</template>
			<template v-else-if="showDefaultPage">
				<DiskbgSlider />
				<DiskbgWhy />
				<DiskbgDownload />
				<DiskbgSubscriptions />
			</template>
			<!-- <template v-if="location"> </template> -->
		</div>
	</div>
</template>

<script>
import DiskbgDownload from "./DiskbgDownload.vue";
import DiskbgSlider from "./DiskbgSlider.vue";
import DiskbgWhy from "./DiskbgWhy.vue";
import DiskbgSubscriptions from "./DiskbgSubscriptions.vue";
import Login from "./Login.vue";
import DiskbgHeader from "./DiskbgHeader.vue";

export default {
	name: "DiskbgLandingPage",
	components: {
		DiskbgDownload,
		DiskbgSlider,
		DiskbgWhy,
		DiskbgSubscriptions,
		Login,
		DiskbgHeader,
	},
	data() {
		return {
			loading: false,
			showLogin: false,
			showDefaultPage: true,
		};
	},
	mounted() {
		this.showLocation();
	},
	computed: {},
	methods: {
		showLoginForm() {
			(this.showLogin = true), (this.showDefaultPage = false);
		},
		showLandingPage() {
			(this.showLogin = false), (this.showDefaultPage = true);
		},
		showLocation() {
			const substr = "verification";
			console.log(location.href.includes(substr));
		},
	},
	created() {
		// const params = this.$route.params;
		if (location.search) {
			(this.showLogin = true), (this.showDefaultPage = false);
		}
		// console.log(params);
		// this.address = params.pop();
	},
};
</script>
