<template>
    <div class="fullscreen-slider">
	<div class="fullscreen-slider__container">
		<div class="slick-initialized slick-slider">
			<div class="slick-list">
				<div class="slick-track" style="opacity: 1; transform: translate3d(0px, 0px, 0px); width: 6490px;">
					<div class="slick-slide fullscreen-slider__image-content" :class="{ 'slick-active': currentIndex === 0 }" data-index="0" tabindex="-1" :style="[currentIndex ===0?{outline: 'none', opacity: 1, width: trackWidth,transition: 'opacity 900ms ease 0s'}:{
							outline: 'none', opacity: 0, width: trackWidth,transition: 'opacity 900ms ease 0s'}]">
                        <template>
						    <img class="fullscreen-slider__image" v-bind:src="require('/themes/diskbg/core/img/slide1.jpg')" alt="Slide 1">
                        </template>
						</div>
						<div data-index="1" class="slick-slide fullscreen-slider__image-content" :class="{ 'slick-active': currentIndex ===1 }" tabindex="-1" :style="[currentIndex ===1?{outline: 'none', opacity: 1, transition: 'opacity 900ms ease 0s', width: trackWidth,left:leftValueSlide1}:{
							outline: 'none', opacity: 0, transition: 'opacity 900ms ease 0s', width: trackWidth,left:leftValueSlide1}]">
                            <template>
							    <img class="fullscreen-slider__image" v-bind:src="require('/themes/diskbg/core/img/slide2.jpg')" alt="Slide 2">
                            </template>
						</div>
						<div data-index="2" class="slick-slide fullscreen-slider__image-content" :class="{ 'slick-active': currentIndex === 2 }" tabindex="-1" :style="[currentIndex ===2?{outline: 'none', opacity: 1, transition: 'opacity 900ms ease 0s', width: trackWidth,left:leftValueSlide2}:{
							outline: 'none', opacity: 0, transition: 'opacity 900ms ease 0s', width: trackWidth,left:leftValueSlide2}]">
                            <template>
							    <img class="fullscreen-slider__image" v-bind:src="require('/themes/diskbg/core/img/slide3.jpg')" alt="Slide 1">
                            </template>
						</div>
					</div>
				</div>
                <ul class="slick-dots" style="display: block;">
                    <li :class="{ 'slick-active': currentIndex === 0 }">
                        <button @click="changeSlide(0)">1</button>
                    </li>
                    <li :class="{ 'slick-active': currentIndex === 1 }">
                        <button @click="changeSlide(1)">2</button>
                    </li>
                    <li :class="{ 'slick-active': currentIndex === 2 }">
                        <button @click="changeSlide(2)">3</button>
                    </li>
                </ul>
			</div>
		</div>
        <div class="fullscreen-slider__text-content">

			<h1 v-if="currentIndex===0">Вземи 10 GB безплатно!</h1>
			<h1 v-else-if="currentIndex===1">Всички ваши файлове винаги с вас.</h1>
			<h1 v-else-if="currentIndex===2">Всички твои файлове на сигурно място.</h1>
			<p v-if="currentIndex===0">Регистрирай се и получи безплатно 10 GB пространство за съхранение на твоите файлове!</p>
			<p v-else-if="currentIndex===1">Работи, споделяй и синхронизирай твоите файлове с Disk.bg, където и да си.</p>
			<p v-else-if="currentIndex===2">Запиши своите файлове в Disk.bg, за да ги имаш от всяко устройство, навсякъде.</p>
			<a href="/apps/diskbg/register">
            <button class="btn mt-20 btn--rounded btn--gradient-darkblue"  style="line-height: 1.5em;">
                <span>
                    <i class="subscription__icon-add-user icon-add-user"></i>
                    <span class="subscription__button-text">Регистрирай се безплатно</span>
                </span>
            </button>
			</a>
        </div>
        <i class="fullscreen-slider__icon-down icon-menu-down"></i>
    </div>
</template>
<script>
export default {
    name: 'DiskbgSlider',
	// props:['showRegister'],
	// setup(props) {},
    data() {
        return {
            loading: false,
            trackWidth: '100%',
			leftValueSlide1:'0px',
			leftValueSlide2:'0px',
			slideAutoChangeTimer:null,
			images: [
                { src: require('/themes/diskbg/core/img/slide1.jpg'), alt: 'Slide 1' },
                { src: require('/themes/diskbg/core/img/slide2.jpg'), alt: 'Slide 2' },
                { src: require('/themes/diskbg/core/img/slide3.jpg'), alt: 'Slide 3' }
            ],
            currentIndex: 0,
        }
    },
    computed: {

    },

	mounted() {
        // Call the method to set initial width and add resize event listener
        this.setTrackWidth();
        window.addEventListener('resize', this.setTrackWidth);
		this.slideAutoChangeTimer=setInterval(this.sliderAutoPlay,9000);
    },

	beforeDestroy() {
        // Remove the resize event listener to prevent memory leaks
        window.removeEventListener('resize', this.setTrackWidth);
    },
    methods: {
		setTrackWidth() {
            // Get the window width and set the track width accordingly
            this.trackWidth = `${window.innerWidth - 17}px`;
			this.leftValueSlide1 = `${-window.innerWidth+17}px`;
			this.leftValueSlide2 = `${-(2*window.innerWidth)+34}px`;
        },
		changeSlide(index) {
            this.currentIndex = index;
			clearInterval(this.slideAutoChangeTimer);
			this.slideAutoChangeTimer=setInterval(this.sliderAutoPlay,9000);
        },

		sliderAutoPlay() {
		if(this.currentIndex===2){
			this.currentIndex=0;
		}
		else{
			this.currentIndex++;
		}
    }
    },
}

</script>
