<template>
	<header class="header">
		<div class="header__logo">
			<a href="/" aria-label="Disk.bg">
				<span class="header__logo-icon"></span>
			</a>
		</div>
		<div class="header__links">
			<a
				class="header__link header__link--contact-us"
				href="/apps/diskbg/contactus"
			>
				<i class="header__icon icon-contact-us"></i>
				<span>Връзка с нас</span>
			</a>
			<div class="header__link header__link--phone">
				<i class="icon-phone"></i>
				<span class="header__link--phone-text">0700 20 127</span>
			</div>
		</div>

		<div class="login-form-header" v-if="showLogin" v-on:click="showLogin">
			<div class="form">
				<span class="button-forgotten-password">
					<button class="btn btn--rounded btn--gradient-lightblue">
						<span>
							<i class="icon-arrow icon-arrow-right"></i>
							<span class="text-uppercase">Вход</span>
							<i class="spinner icon-spinner2"></i>
						</span>
					</button>
					<!-- <a class="forgotten-password-login under-button" href="#/forgotten-password">Забравена парола?</a> -->
				</span>
			</div>
		</div>
		<div class="login-form-header" v-else>
			<div class="form">
				<a href="/login?clear=1" class="button-forgotten-password">
					<button class="btn btn--rounded btn--gradient-lightblue">
						<span>
							<i class="icon-arrow icon-arrow-right"></i>
							<span class="text-uppercase">Вход</span>
							<i class="spinner icon-spinner2"></i>
						</span>
					</button>
					<!-- <a class="forgotten-password-login under-button" href="#/forgotten-password">Забравена парола?</a> -->
				</a>
			</div>
		</div>

		<!-- <span class="button-forgotten-password"><button class="btn btn--rounded btn--gradient-lightblue"><span><i class="icon-arrow icon-arrow-right"></i><span class="text-uppercase">Вход</span><i class="spinner icon-spinner2"></i></span></button><a class="forgotten-password-login under-button" href="#/forgotten-password">Забравена парола?</a></span> -->
		<!-- <span class="button-login">
		<button class="btn btn--rounded btn--gradient-lightblue">
			<span>
				<i class="icon-arrow icon-arrow-right"></i>
				<span class="text-uppercase">Вход</span>
				<i class="spinner icon-spinner2"></i>
			</span>
		</button>
	</span> -->
	</header>
</template>
<script>
export default {
	name: "DiskbgHeader",
	props: ["showLogin", "showDefaultPage", "showContactUs"],
	setup(props) {},
	data() {
		return {
			loading: false,
		};
	},
	computed: {},
	mounted() {},

	beforeDestroy() {},
	methods: {},
};
</script>
