<!--
  - @copyright 2019 Christoph Wurst <christoph@winzerhof-wurst.at>
  -
  - @author 2019 Christoph Wurst <christoph@winzerhof-wurst.at>
  -
  - @license GNU AGPL version 3 or any later version
  -
  - This program is free software: you can redistribute it and/or modify
  - it under the terms of the GNU Affero General Public License as
  - published by the Free Software Foundation, either version 3 of the
  - License, or (at your option) any later version.
  -
  - This program is distributed in the hope that it will be useful,
  - but WITHOUT ANY WARRANTY; without even the implied warranty of
  - MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  - GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.  If not, see <http://www.gnu.org/licenses/>.
  -->

<template>
	<form class="login-form" @submit.prevent="submit">
		<fieldset class="login-form__fieldset">
			<NcTextField id="user" :value.sync="user" name="user" autocapitalize="off"
				:label="t('core', 'Login or email')" required @change="updateUsername" />
			<SubmitButton :value="t('core', 'Reset password')" />

			<NcNoteCard v-if="message === 'send-success'" type="success">
				{{ displayMessage !== '' && displayMessage !== undefined ? displayMessage : `Имейл с линк за
				потвърждение бе изпратен до Вашия имейл адрес.` }}
				<!-- {{ t('core', 'If this account exists, a password reset message has been sent to its email address. If you do not receive it, verify your email address and/or Login, check your spam/junk folders or ask your local administration for help.') }} -->
			</NcNoteCard>
			<NcNoteCard v-else-if="message === 'send-error'" type="error">
				{{ displayMessage !== '' && displayMessage !== undefined ? displayMessage : t('core', `Couldn\'t send
				reset email.Please contact your administrator.`) }}
				<!-- {{ t('core', 'Couldn\'t send reset email. Please contact your administrator.') }} -->
			</NcNoteCard>
			<NcNoteCard v-else-if="message === 'reset-error'" type="error">
				{{ displayMessage !== '' && displayMessage !== undefined ? displayMessage : t('core', `Password cannot
				be changed.Please contact your administrator.`) }}
				<!-- {{ t('core', 'Password cannot be changed. Please contact your administrator.') }} -->
			</NcNoteCard>

			<a class="login-form__link" href="#" @click.prevent="$emit('abort')">
				{{ t('core', 'Back to login') }}
			</a>
			<a v-if="displayMessage == 'Вашият профил трябва да бъде активиран, за да можете да правите промени.'"
				@click.prevent="sendResetPasswordLink">
				Изпрати нов Код
				<!-- {{ t('core', 'If this account exists, a password reset message has been sent to its email address. If you do not receive it, verify your email address and/or Login, check your spam/junk folders or ask your local administration for help.') }} -->
			</a>
		</fieldset>
	</form>
</template>

<script>
import axios from '@nextcloud/axios'
import { generateUrl } from '@nextcloud/router'
import SubmitButton from './SubmitButton.vue'
import NcTextField from '@nextcloud/vue/dist/Components/NcTextField.js'
import NcNoteCard from '@nextcloud/vue/dist/Components/NcNoteCard.js'

export default {
	name: 'ResetPassword',
	components: {
		SubmitButton,
		NcNoteCard,
		NcTextField,
	},
	props: {
		username: {
			type: String,
			required: true,
		},
		resetPasswordLink: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			error: false,
			loading: false,
			message: undefined,
			displayMessage: undefined,
			user: this.username,
		}
	},
	watch: {
		username(value) {
			this.user = value
		},
	},
	methods: {
		updateUsername() {
			this.$emit('update:username', this.user)
		},
		sendResetPasswordLink() {
			this.loading = true
			this.error = false
			const data = {
				email: this.user,
			}
			console.log(data);
			const url = generateUrl('apps/diskbg/r/activation')


			return axios.post(url, data)
				.then(resp => {
					console.log(resp);
					if (resp.data['data'].status !== 'ok') {
						this.displayMessage = resp.data['data'].message;
						throw new Error(`got status ${resp.data.status}`)
					}
					if (resp.data['data'].status === 'ok') {
						this.displayMessage = resp.data['data'].message;
					}

					this.message = 'send-success'
				})
				.catch(e => {
					console.error('Could not send reset email request', e)
					this.error = true
					this.message = 'send-error'
				})
				.then(() => this.loading = false)

		},
		submit() {
			this.loading = true
			this.error = false
			this.message = ''
			this.displayMessage = ''
			const url = generateUrl('apps/diskbg/r/forgottenpassword')

			const data = {
				user: this.user,
			}

			return axios.post(url, data)
				.then(resp => {
					if (resp.data['data'].status !== 'ok') {
						this.displayMessage = resp.data['data'].messages[0];
						throw new Error(`got status ${resp.data['data'].status}`)
					}
					if (resp.data['data'].status === 'ok') {
						this.displayMessage = resp.data['data'].messages[0];
					}

					this.message = 'send-success'
				})
				.catch(e => {
					console.error('Could not send reset email request', e)
					this.error = true
					this.message = 'send-error'
				})
				.then(() => this.loading = false)
		},
	},
}
</script>

<style lang="scss" scoped>
.login-form {
	text-align: left;
	font-size: 1rem;

	&__fieldset {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: .5rem;
	}

	&__link {
		display: block;
		font-weight: normal !important;
		padding-bottom: 1rem;
		cursor: pointer;
		font-size: var(--default-font-size);
		text-align: center;
		padding: .5rem 1rem 1rem 1rem;
	}
}
</style>